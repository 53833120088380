import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import PhotoAlbum from "../components/PhotoAlbum";

const AlbumHeader = styled.section`
    margin-top: 84px;
    padding: 0 18px;
    position: relative;
    &.secondary {
        margin-top: 0;
    }
    h1 {
        font-size: 2.25rem;
        color: var(--green);
        text-align: center;
        position: relative;
        margin-bottom: 8px;
		padding-top: 30px;
    }
    h2 {
        font-size: 1.75rem;
        color: var(--green);
        text-align: center;
        position: relative;
        margin-bottom: 8px;
		padding-top: 30px;
    }
    span {
        display: block;
        font-size: .6875rem;
        text-align: center;
        color: var(--sand-med);
		margin-bottom: 36px;
    }
`

const Intro = styled.p`
    font-size: 1.125rem;
    max-width: 600px;
    margin: 20px auto 60px;
    text-align: center;
`

export default function LalaBree() {
    const basePics = useStaticQuery(graphql`
		{
			headshotsFull: allFile(
				filter: {
                    relativeDirectory: {eq: "albums/other-work/headshots"},
                    name: {glob: "*-full"}}
                sort: {fields: name}
			) {
				edges {
					node {
						childImageSharp {
							full: gatsbyImageData(layout: FULL_WIDTH)
						}
						name
					}
				}
			}
			headshotsThumb: allFile(
                filter: {
                    relativeDirectory: {eq: "albums/other-work/headshots"},
                    name: {glob: "*-thumb"}}
                sort: {fields: name}
            ) {
                edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 320,
                            height: 320,
                            placeholder: BLURRED
                            )
                        }
                        name
                    }
                }
            }
            realEstateFull: allFile(
				filter: {
                    relativeDirectory: {eq: "albums/other-work/real-estate"},
                    name: {glob: "*-full"}}
                sort: {fields: name}
			) {
				edges {
					node {
						childImageSharp {
							full: gatsbyImageData(layout: FULL_WIDTH)
						}
						name
					}
				}
			}
			realEstateThumb: allFile(
                filter: {
                    relativeDirectory: {eq: "albums/other-work/real-estate"},
                    name: {glob: "*-thumb"}}
                sort: {fields: name}
            ) {
                edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(
                            width: 320,
                            height: 320,
                            placeholder: BLURRED
                            )
                        }
                        name
                    }
                }
            }
		}
	`);
    //////////////////////////////////////////////////////////////
    // HEADSHOTS
    //////////////////////////////////////////////////////////////
    // 1. Creat an empty array to populate for the album
	let headshotsImages = [];

	// 2. Build array of thumbnails
	const headshotsThumbs = basePics.headshotsThumb.edges.map(({ node }) => node.childImageSharp);

	// 3. Set variable to iterate through thumbs array
	let headshotsThumbIt = 0;

	// 4. Build and iterate over array of full size images
	basePics.headshotsFull.edges.map(({ node }) => {
		headshotsImages.push({
			...headshotsThumbs[headshotsThumbIt],
			...node.childImageSharp,
			thumbAlt: `Headshots ${node.name} thumbnail`,
			title: `Headshots ${node.name} full`,
		});
		headshotsThumbIt++;
	});
    //////////////////////////////////////////////////////////////
    // REAL ESTATE
    //////////////////////////////////////////////////////////////
    // 1. Creat an empty array to populate for the album
	let realEstateImages = [];

	// 2. Build array of thumbnails
	const realEstateThumbs = basePics.realEstateThumb.edges.map(({ node }) => node.childImageSharp);

	// 3. Set variable to iterate through thumbs array
	let realEstateThumbIt = 0;

	// 4. Build and iterate over array of full size images
	basePics.realEstateFull.edges.map(({ node }) => {
		realEstateImages.push({
			...realEstateThumbs[realEstateThumbIt],
			...node.childImageSharp,
			thumbAlt: `Real Estate ${node.name} thumbnail`,
			title: `Real Estate ${node.name} full`,
		});
		realEstateThumbIt++;
	});
    return (
        <Layout alwaysSticky={true}>
            <Helmet>
                <title>Other Work :: Tide &amp; Tone Photography</title>
            </Helmet>
            <AlbumHeader>
                <h1>Other Work</h1>
                <Intro>This section is to show photography work offer outside of families.</Intro>
                <h2>Headshots</h2>
            </AlbumHeader>
			<PhotoAlbum albumImages={headshotsImages} />
            <AlbumHeader className="secondary">
                <h2>Real Estate</h2>
            </AlbumHeader>
			<PhotoAlbum albumImages={realEstateImages} />
        </Layout>
    )
}